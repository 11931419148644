import { Link, graphql, withPrefix } from 'gatsby';
import React from 'react';

import groupShot from '../assets/images/nurturing-simplicity.jpg';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Profile = ({ name, title, url, email, image, children }) => {
  return (
    <div className='w-full sm:w-1/2 mt-4 px-2 flex flex-col'>
      <div className='flex'>
        <div className='p-2 w-40 h-40'>
          <img alt={name} className='rounded-full ' src={withPrefix(image)} />
        </div>
        <div className='w-2/3 flex flex-col'>
          <h2 className='text-2xl font-semibold'>{name}</h2>
          <div className='leading-tight'>
            <p className='font-medium'>{title}</p>
            <p className='text-xs green-700'>
              <a className='hover:underline' href={`mailto:${email}`}>
                {email}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='pt-2'>{children}</div>
    </div>
  );
};

const Team = ({ data }) => {
  const members = data.allMarkdownRemark.edges;
  console.log(members);

  return (
    <Layout hasHero={false} heroSize='30rem' heroImage={groupShot}>
      <SEO
        title='Meet Our Team '
        description='Our team at City of Oaks Funeral Home provides unsurpassed service for direct cremation, traditional funerals, or immediate burial. In Raleigh, serving all of North Carolina.'
      />

      <div className='container w-3/4 md:w-1/2 mt-20 font-serif font-hairline self-start'>
        <h1 className='text-3xl md:text-5xl text-green-700 leading-tight'>
          Meet Our Team
        </h1>
        <p className='text-base'>
          Our caring and talented team is here to to make this process bearable
          and affordable. Let our family help yours.{' '}
        </p>
      </div>

      <div className='flex flex-wrap mt-10 md:mt-16 lg:mt-24'>
        {members
          .sort((a, b) =>
            a.node.frontmatter.weight > b.node.frontmatter.weight
              ? 1
              : b.node.frontmatter.weight > a.node.frontmatter.weight
              ? -1
              : 0
          )
          .map(({ node }) => {
            const { title, jobtitle, email, image, path } = node.frontmatter;
            return (
              <Profile
                name={title}
                title={jobtitle}
                email={email}
                image={withPrefix(image)}
                key={path}
              >
                {node.excerpt}
              </Profile>
            );
          })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamMemberQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/team/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            jobtitle
            email
            image
            path
            weight
          }
          excerpt(pruneLength: 1000)
        }
      }
    }
  }
`;

export default Team;
